import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({ fill }) {
  return (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      viewBox="0 0 78 34"
      fill="none"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        fontFamily="TekoBold"
        // fontSize="45"
        // fontWeight="bold"
      >
        <text>
          <tspan x="0" y="22" fill={fill}>
            PIXEL
          </tspan>
          <tspan x="37" y="22" fill="#7A8084">
            MAGE
          </tspan>
        </text>
      </g>
    </svg>
  </div>
  );
}
