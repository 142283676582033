import React from "react";

import { Icon } from '@types';

const RSSIcon: Icon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    className="icon"
    viewBox="0 0 1024 1024"
  >
  <path 
    fill={fill}
    d="M724.4 304.4C566.6 144 374.8 66.3 151.4 66.3c-21.9 0-41.2 7.2-58.2 24.2-14.6 17.1-24.4 34.1-21.9 58.4-2.4 21.9 7.2 43.7 21.9 58.2 17 14.5 36.4 24.2 58.2 24.2 179.7 0 330.3 63.1 456.6 189.4 126.2 124 189.3 274.5 189.3 454.2-2.4 21.9 7.2 41.4 21.9 58.4 17 14.6 36.5 24.2 58.2 24.2 24.2 0 43.7-9.7 58.4-24.2 17-17 24.2-36.4 24.2-58.4.1-225.7-77.6-415.1-235.6-570.5zm-573 51c145.7 0 267.2 51 369.1 150.6 99.6 102 150.6 223.4 150.6 369.1 0 21.9-7.2 41.4-21.9 58.4-17 14.6-36.5 24.2-58.2 24.2-24.2 0-43.7-9.7-58.2-24.2-17-17-24.2-36.4-24.2-58.4 0-99.6-36.5-182.1-104.5-252.6-70.5-68-153-104.3-252.6-104.3-21.9 0-41.2-7.2-58.2-21.9-14.6-17-24.2-36.5-21.9-58.4-2.5-24.2 7.2-43.7 21.9-58.4 16.8-16.9 36.2-24.1 58.1-24.1zm58.3 308.4c38.9 0 75.2 14.5 102 41.2 26.7 29.1 43.7 63.1 43.7 104.5 0 38.9-17 75.2-43.7 102-26.7 29.1-63.1 43.7-102 43.7-41.2 0-75.4-14.6-104.5-43.7-26.7-26.8-41.2-63.2-41.2-102 0-41.4 14.5-75.4 41.2-104.5 29.1-26.7 60.7-41.2 99.6-41.2h4.9z">  
  </path>
</svg>
);

export default RSSIcon;
